// Chakra imports
import {
  Alert,
  AlertIcon,
  Badge,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  Select,
  Textarea,
  Link,
  Switch,
  Spinner,
  Text,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure
} from "@chakra-ui/react";
// Assets
import BgSignUp from "assets/img/BgSignUp.png";
import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { FaUserGraduate, FaUser } from "react-icons/fa";
import axios from "axios";
import { ENDPOINTS } from "../../../api/constants.js";
import moment from "moment";

function Case(props) {
  const titleColor = useColorModeValue("blue", "blue.200");
  const textColor = useColorModeValue("gray.700", "white");
  const bgColor = useColorModeValue("white", "gray.700");
  const bgIcons = useColorModeValue("blue", "rgba(255, 255, 255, 0.5)");
  const [loading, setLoading] = useState(true);
  const [caseDetail, setCaseDetails] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const bgStatus = useColorModeValue("gray.400", "#1a202c");
  const colorStatus = useColorModeValue("white", "gray.400");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalDetail, setModalDetail] = useState("");

  useEffect(() => {
    try {
      const user = JSON.parse(localStorage.getItem('userDetails'));
      const onboardedLawyer = JSON.parse(localStorage.getItem('lawyerOnboarded'));

      if (user.user.role === 'lawyer' && user.user.lawyerOnboarded === false) {
        props.history.push('/admin/lawyer-onboard');
      }

      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.token}`
        }
      };

      const url = process.env.REACT_APP_API_BASE + ENDPOINTS.cases + '/' + props.match.params.caseId + '/';

      axios
        .get(url, config)
        .then((res) => {
          setLoading(false);
          setCaseDetails(res.data.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error);
            setLoading(false);
          } else if (error.request) {
            console.log(error);
            setLoading(false);
          } else {
            console.log(error);
            setLoading(false);
          }
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const acceptCase = () => {
    setLoading(true);
    setModalDetail("accept")
    onOpen();

    try {
      const user = JSON.parse(localStorage.getItem('userDetails'));

      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.token}`
        }
      };

      const data = {
        lawyerStatus: "accepted",
        status: "in-progress"
      };

      const url = process.env.REACT_APP_API_BASE + ENDPOINTS.cases + '/' + props.match.params.caseId + '/';

      axios
        .patch(url, data, config)
        .then((res) => {
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          if (error.response) {
            console.log(error);
            setErrorMessage('Problem accepting case');
          } else if (error.request) {
            console.log(error);
            setErrorMessage('Problem accepting case');
          } else {
            console.log(error);
            setErrorMessage('Problem accepting case');
          }
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
      setErrorMessage('Problem accepting case');
    }
  };

  const rejectCase = () => {
    setModalDetail("reject")
    onOpen();

    try {
      const user = JSON.parse(localStorage.getItem('userDetails'));

      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.token}`
        }
      };

      const data = {
        lawyerStatus: "rejected",
      };

      const url = process.env.REACT_APP_API_BASE + ENDPOINTS.cases + '/reject/' + props.match.params.caseId + '/';

      axios
        .post(url, data, config)
        .then((res) => {
          setLoading(false);
          props.history.push('/admin/cases');
        })
        .catch((error) => {
          setLoading(false);
          if (error.response) {
            console.log(error);
            setErrorMessage('Problem rejecting case');
          } else if (error.request) {
            console.log(error);
            setErrorMessage('Problem rejecting case');
          } else {
            console.log(error);
            setErrorMessage('Problem rejecting case');
          }
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
      setErrorMessage('Problem rejecting case');
    }
  }

  return (
    <>
      <Flex
        direction='column'
        alignSelf='center'
        justifySelf='center'
        overflow='hidden'>
        <Flex alignItems='center' justifyContent='center' mb='60px' mt='20px'>
          {
            loading ? (
              <Spinner
                thickness='4px'
                speed='0.65s'
                emptyColor='gray.200'
                color='blue'
                size='xl'
                ml='45%'
              />
            ) : (
              Object.keys(caseDetail).length > 0 ? (
                <Flex
                  direction='column'
                  w='95%'
                  background='transparent'
                  borderRadius='15px'
                  p='40px'
                  mt='5rem'
                  bg={bgColor}
                  boxShadow='0 20px 27px 0 rgb(0 0 0 / 5%)'>
                  <Text
                    fontSize='xl'
                    color={textColor}
                    fontWeight='bold'
                    textAlign='center'
                    mb='5px'>
                    Case Details
                  </Text>
                  <Text
                    fontSize='sm'
                    color='gray.400'
                    fontWeight='normal'
                    textAlign='center'
                    mb='15px'>
                    Submitted {moment(caseDetail.createdAt).fromNow()}
                  </Text>

                  <Text
                    fontSize='sm'
                    color='black.400'
                    fontWeight='normal'
                    mb='15px'>
                    {caseDetail.description}
                  </Text>

                  <Badge
                    bg={caseDetail.status === "solved" ? "green.400" : bgStatus}
                    color={caseDetail.status === "Solved" ? "white" : colorStatus}
                    fontSize="16px"
                    p="3px 10px"
                    borderRadius="8px"
                    mt={10}
                    mb={5}
                  >
                    {caseDetail.status}
                  </Badge>

                  <Text
                    fontSize='sm'
                    color='gray.400'
                    fontWeight='normal'
                    mb='5px'>
                    {JSON.parse(localStorage.getItem('userDetails')).user.role === 'lawyer' ? "Client" : "Lawyer"}
                  </Text>
                  <Text
                    fontSize='xl'
                    color={textColor}
                    fontWeight='bold'
                    mb='5px'>
                    {JSON.parse(localStorage.getItem('userDetails')).user.role === 'lawyer' ? (`${caseDetail.userId.firstName} ${caseDetail.userId.lastName}`) : (`${caseDetail.lawyerId.firstName} ${caseDetail.lawyerId.lastName}`)}
                  </Text>

                  {
                    JSON.parse(localStorage.getItem('userDetails')).user.role === 'lawyer' && caseDetail.lawyerStatus === 'pending' ? (
                      <Flex alignItems='center' justifyContent='center'>
                        <Button variant='ghost' onClick={rejectCase} >Reject</Button>
                        <Button bg='blue' color='white' _hover={{ bg: "blue.200" }} mr={3} onClick={acceptCase}>
                          Accept
                        </Button>
                      </Flex>
                    ) : (
                      null
                    )
                  }

                </Flex>
              ) : (
                <Alert status='error' mb='24px' mt='5rem'>
                  <AlertIcon />
                  Could not fetch case <Link color={titleColor} as={RouterLink} ms='5px' fontWeight='bold' to="/admin/cases">
                    Go Back
                  </Link>
                </Alert>
              )
            )
          }
        </Flex>
      </Flex>

      <Modal closeOnOverlayClick={false} size={"xl"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          {modalDetail === "accept" ?
            (<>
              <ModalHeader>Congratulations!</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Text mb={8}>You have successsfully accepted the case.</Text>
              </ModalBody>
              <ModalFooter>
                <Button bg='blue' color='white' _hover={{ bg: "blue.200" }} onClick={onClose}>
                  Back to cases
                </Button>
              </ModalFooter>
            </>
            ) : (
              <>
                <ModalHeader>Congratulations!</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Text mb={8}>You have rejected the case.</Text>
                </ModalBody>
                <ModalFooter>
                  <Button bg='blue' color='white' _hover={{ bg: "blue.200" }} onClick={onClose}>
                    Back to cases
                  </Button>
                </ModalFooter>
              </>
            )
          }

        </ModalContent>
      </Modal>
    </>
  );
}

export default Case;     