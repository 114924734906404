import {
  Avatar,
  AvatarGroup,
  Badge,
  Flex,
  Icon,
  Progress,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import moment from "moment";

function DashboardTableRow(props) {
  const { id, description, dateCreated, status } = props;
  const textColor = useColorModeValue("gray.700", "white");
  const bgStatus = useColorModeValue("gray.400", "#1a202c");
  const colorStatus = useColorModeValue("white", "gray.400");
  return (
    <Tr key={id}>
      <Td minWidth={{ sm: "250px" }} pl="0px">
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          {/* <Icon as={logo} h={"24px"} w={"24px"} pe="5px" /> */}
          <Text
            fontSize="md"
            color={textColor}
            fontWeight="bold"
            minWidth="100%"
          >
            {id}
          </Text>
        </Flex>
      </Td>

      <Td>
        <Flex>
          {/* <AvatarGroup size="sm" mr=".5rem">
            {lawyer.map((member) => {
              return (
                <Avatar
                  name="Ryan Florence"
                  key={member}
                  src={member}
                  _hover={{ zIndex: "3", cursor: "pointer" }}
                />
              );
            })}
          </AvatarGroup> */}
          <Text fontSize="md" color={'gray'} fontWeight="bold" pt=".4rem">
            {description.substring(0, 15) + '...'}
          </Text>
        </Flex>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {moment(dateCreated).fromNow()}
        </Text>
      </Td>
      <Td>
        <Badge
          bg={status === "Completed" ? "green.400" : bgStatus}
          color={status === "Completed" ? "white" : colorStatus}
          fontSize="16px"
          p="3px 10px"
          borderRadius="8px"
        >
          {status}
        </Badge>
        {/* <Flex direction="column">
          <Text
            fontSize="md"
            color="teal.300"
            fontWeight="bold"
            pb=".2rem"
          >{`${progression}%`}</Text>
          <Progress
            colorScheme={progression === 100 ? "teal" : "cyan"}
            size="xs"
            value={progression}
            borderRadius="15px"
          />
        </Flex> */}
      </Td>
    </Tr>
  );
}

export default DashboardTableRow;
