import React, {useState} from "react";
import { Link as RouterLink } from "react-router-dom";
// Chakra imports
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Flex,
  Text,
  Link,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import BgSignUp from "assets/img/BgSignUp.png";

function SignUpSuccess() {
  const titleColor = useColorModeValue("blue", "blue.200");
  const textColor = useColorModeValue("gray.700", "white");
  const bgColor = useColorModeValue("white", "gray.700");
  const bgIcons = useColorModeValue("blue", "rgba(255, 255, 255, 0.5)");


  return (
    <Flex
      direction='column'
      alignSelf='center'
      justifySelf='center'
      overflow='hidden'>
      <Box
        position='absolute'
        minH={{ base: "70vh", md: "50vh" }}
        w={{ md: "calc(100vw - 50px)" }}
        borderRadius={{ md: "15px" }}
        left='0'
        right='0'
        bgRepeat='no-repeat'
        overflow='hidden'
        zIndex='-1'
        top='0'
        // bgImage={BgSignUp}
        // bg={'blue'}
        bgSize='cover'
        mx={{ md: "auto" }}
        mt={{ md: "14px" }}></Box>
      <Flex alignItems='center' justifyContent='center' mb='60px' mt='20px'>
        <Flex
          direction='column'
          w='445px'
          background='transparent'
          borderRadius='15px'
          p='0px'
          // mx={{ base: "100px" }}
          bg={bgColor}
          mt='10rem'
          boxShadow='0 20px 27px 0 rgb(0 0 0 / 5%)'>
          <Alert
            status='success'
            variant='subtle'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
            textAlign='center'
            height='200px'
          >
            <AlertIcon boxSize='40px' mr={0} />
            <AlertTitle mt={4} mb={1} fontSize='lg'>
              Account Created!
            </AlertTitle>
            <AlertDescription maxWidth='sm'>
              Thanks for signing up. Please click the link below to login. <br /><br />
              <Link
                color={titleColor}
                as={RouterLink}
                ms='5px'
                to='/auth/signin'
                fontWeight='bold'>
                Sign In
              </Link>
            </AlertDescription>
          </Alert>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default SignUpSuccess;
