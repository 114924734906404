// Chakra imports
import {
  Alert,
  AlertIcon,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Textarea,
  Link,
  Switch,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { PhoneIcon } from '@chakra-ui/icons'
// Assets
import BgSignUp from "assets/img/BgSignUp.png";
import React, {useState, useEffect} from "react";
import { Link as RouterLink } from "react-router-dom";
import { FaUserGraduate, FaUser } from "react-icons/fa";
import axios from "axios";
import { ENDPOINTS } from "../../../api/constants.js";

function LawyerOnboard(props) {
  const titleColor = useColorModeValue("blue", "blue.200");
  const textColor = useColorModeValue("gray.700", "white");
  const bgColor = useColorModeValue("white", "gray.700");
  const bgIcons = useColorModeValue("blue", "rgba(255, 255, 255, 0.5)");
  const [loading, setLoading] = useState(false);
  const [categoriesList, setCategoriesList] = useState([]);
  const [category, setCategory] = useState('');
  const [practicingCert, setPracticingCert] = useState(null);
  const [curriculumVitae, setCurriculumVitae] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    try {
      const user = JSON.parse(localStorage.getItem('userDetails'));

      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.token}`
        }
      };

      const url = process.env.REACT_APP_API_BASE + ENDPOINTS.categories;

      axios
        .get(url, config)
        .then((res) => {
          setCategoriesList(res.data.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error);
          } else if (error.request) {
            console.log(error);
          } else {
            console.log(error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const submitDocs = e => {
    e.preventDefault();
    setLoading(true);

    try {
      const user = JSON.parse(localStorage.getItem('userDetails'));
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.token}`
        }
      };

      const bodyFormData = new FormData();
      bodyFormData.append('userId', user.user.id);
      bodyFormData.append('categoryId', category);
      bodyFormData.append('practicingCertificate', practicingCert);
      // bodyFormData.append('curriculumVitae', curriculumVitae);

      const url = process.env.REACT_APP_API_BASE + ENDPOINTS.lawyers;

      axios
        .post(url, bodyFormData, config)
        .then((res) => {
          props.history.push('/admin/onboard-success');
        })
        .catch((error) => {
          setLoading(false);
          if (error.response) {
            console.log(error);
            setErrorMessage('Problem submitting docs');
          } else if (error.request) {
            console.log(error);
            setErrorMessage('Problem submitting docs');
          } else {
            console.log(error);
            setErrorMessage('Problem submitting docs');
          }
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
      setErrorMessage('Problem submitting docs');
    }
  };

  return (
    <Flex
      direction='column'
      alignSelf='center'
      justifySelf='center'
      overflow='hidden'>
      <Flex alignItems='center' justifyContent='center' mb='60px' mt='20px'>
        <Flex
          direction='column'
          w='95%'
          background='transparent'
          borderRadius='15px'
          p='40px'
          mt='5rem'
          bg={bgColor}
          boxShadow='0 20px 27px 0 rgb(0 0 0 / 5%)'>
          <Text
            fontSize='xl'
            color={textColor}
            fontWeight='bold'
            textAlign='center'
            mb='5px'>
            Lawyer Onboarding
          </Text>
          <Text
            fontSize='sm'
            color='gray.400'
            fontWeight='normal'
            textAlign='center'
            mb='15px'>
            Please fill your details
          </Text>
          <form onSubmit={submitDocs}>
            <FormControl>
              <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                Field of practice
              </FormLabel>

              <Select 
                value={category} 
                mb='24px' 
                onChange={e => setCategory(e.target.value)}
                required>
                <option value=''>Select</option>
                {
                  categoriesList.map((item, index) => (
                    <option key={index} value={item._id}>{item.categoryName}</option>
                  ))
                }
              </Select>

              <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                Practicing Certificate (Should be an image less than 3MB)
              </FormLabel>
              <Input 
                type='file'
                // value={practicingCert}
                accept="image/*"
                onChange={e => setPracticingCert(e.target.files[0])}
                required
                pt='.25rem'
                mb='24px' />

              {/* <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                Curriculum Vitae (PDF or Image, less than 3MB)
              </FormLabel>
              <Input 
                type='file'
                // value={curriculumVitae}
                accept=".pdf"
                onChange={e => setCurriculumVitae(e.target.files[0])}
                required
                pt='.25rem'
                mb='24px' /> */}
              {
                errorMessage.length > 0 ? (
                  <Alert status='error' mb='24px'>
                    <AlertIcon />
                    There was an error processing your request
                  </Alert>
                ) : (
                  null
                )
              }
              <Button
                isLoading={loading}
                loadingText="Submitting case..."
                type='submit'
                bg='blue'
                fontSize='10px'
                color='white'
                fontWeight='bold'
                w='100%'
                h='45'
                mb='24px'
                _hover={{
                  bg: "blue.200",
                }}
                _active={{
                  bg: "blue.400",
                }}>
                Submit
              </Button>
            </FormControl>
          </form>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default LawyerOnboard;
