// Chakra imports
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Avatar,
  Badge,
  Box,
  Button, 
  Text,
  Flex,
  useColorModeValue,
  Wrap,
  WrapItem,
  Center,
  SimpleGrid
} from "@chakra-ui/react";
import IconBox from "components/Icons/IconBox";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { ENDPOINTS } from "../../../api/constants.js";

function SelectLawyer(props) {
  const textColor = useColorModeValue("gray.700", "white");
  const [loading, setLoading] = useState(false);
  const [selectedLawyer, setSelectedLawyer] = useState('');

  useEffect(() => {
    try {
      const user = JSON.parse(localStorage.getItem('userDetails'));
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `JWT ${user.access}`
        }
      };

      const url = process.env.REACT_APP_API_BASE + ENDPOINTS.firm + 'lawyers/';

      axios
        .get(url, config)
        .then((res) => {
          // setUserList(res.data.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error);
          } else if (error.request) {
            console.log(error);
          } else {
            console.log(error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const selectLawyerFunc = (lawyerId) => {
    setSelectedLawyer(lawyerId);
    
  };

  const submitLawyer = e => {
    e.preventDefault();
    setLoading(true);

    
  };

  return (
    <Flex direction='column' pt={{ base: "120px", md: "75px" }}>
      <Alert status='success'>
        <AlertIcon />
        <AlertTitle>Success!</AlertTitle>
        <AlertDescription>Your case has been submitted. These are the best lawyers matched with your case.</AlertDescription>
      </Alert>
      <SimpleGrid columns={2} spacing={10} mt='2rem' mb='3rem'>
        <Box height='80px'>
          <Text
            fontSize='xl'
            color={textColor}
            fontWeight='bold'>
            Lawyers
          </Text>
        </Box>
        <Box height='80px' alignContent={'flex-end'} textAlign={'right'}>
          <Button
            isLoading={loading}
            loadingText="Submitting your choice..."
            fontSize='10px'
            bg='blue'
            w='30%'
            h='45'
            mb='20px'
            color='white'
            _hover={{
              bg: "blue.200",
            }}
            _active={{
              bg: "blue.400",
            }}
            disabled={selectedLawyer !== '' ? false : true}
            onClick={() => submitLawyer()}>Done</Button>
        </Box>
      </SimpleGrid>
      

      <Wrap>
        <WrapItem cursor={'pointer'} onClick={() => selectLawyerFunc(3)}>
          <Center 
            w='300px' 
            h='120px' 
            bg={selectedLawyer === 3 ? 'blue' : ''}
            border={selectedLawyer === 3 ? '2px solid blue' : '2px solid gray'}
            borderRadius={15} padding={5}>
            <Flex>
              <Avatar 
                name='Oshigaki Kisame' 
                src='https://bit.ly/broken-link' 
                color={selectedLawyer === 3 ? 'white' : ''} />
              <Box ml='3'>
                <Text 
                  fontWeight='bold' 
                  color={selectedLawyer === 3 ? 'white' : ''}>
                  Oshigaki Kisame
                </Text>
                <Text 
                  fontSize='xs' 
                  mb='0.5rem'
                  color={selectedLawyer === 3 ? 'white' : ''}>Tech Lawyer</Text>

                <Text 
                  fontSize='sm' 
                  color={selectedLawyer === 3 ? 'white' : ''}>KSH 2000 / hr</Text>
              </Box>
            </Flex>
          </Center>
        </WrapItem>
      </Wrap>
    </Flex>
  );
}

export default SelectLawyer;
