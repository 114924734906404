// Chakra imports
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  Link,
  Switch,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import BgSignUp from "assets/img/BgSignUp.png";
import React, {useState} from "react";
import { Link as RouterLink } from "react-router-dom";
import { FaUserGraduate, FaUser } from "react-icons/fa";
import axios from "axios";
import { ENDPOINTS } from "../../api/constants.js";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

function SignUp(props) {
  const titleColor = useColorModeValue("blue", "blue.200");
  const textColor = useColorModeValue("gray.700", "white");
  const bgColor = useColorModeValue("white", "gray.700");
  const bgIcons = useColorModeValue("blue", "rgba(255, 255, 255, 0.5)");
  const [loading, setLoading] = useState(false);
  const [isLawyer, setIsLawyer] = useState(false);
  const [isCustomer, setIsCustomer] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const signUpUser = e => {
    e.preventDefault();
    setLoading(true);

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const data = {
      firstName: firstName,
      lastName: lastName,
      phone,
      role: isCustomer === true ? 'client' : 'lawyer',
      password,
    };

    const url = process.env.REACT_APP_API_BASE + ENDPOINTS.user;

    axios
      .post(url, data, config)
      .then((res) => {
        localStorage.setItem('userDetails', JSON.stringify(res.data));
        setLoading(false);
        props.history.push('/auth/verify-code');
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          console.log(error);
          setErrorMessage('Problem signin up');
        } else if (error.request) {
          console.log(error);
          setErrorMessage('Problem signin up');
        } else {
          console.log(error);
          setErrorMessage('Problem signin up');
        }
      });
  }

  return (
    <Flex
      direction='column'
      alignSelf='center'
      justifySelf='center'
      overflow='hidden'>
      <Box
        position='absolute'
        minH={{ base: "70vh", md: "50vh" }}
        w={{ md: "calc(100vw - 50px)" }}
        borderRadius={{ md: "15px" }}
        left='0'
        right='0'
        bgRepeat='no-repeat'
        overflow='hidden'
        zIndex='-1'
        top='0'
        // bgImage={BgSignUp}
        bg={'blue'}
        bgSize='cover'
        mx={{ md: "auto" }}
        mt={{ md: "14px" }}></Box>
      <Flex
        direction='column'
        textAlign='center'
        justifyContent='center'
        align='center'
        mt='6.5rem'
        mb='30px'>
        <Text fontSize='4xl' color='white' fontWeight='bold'>
          Welcome to Instruct
        </Text>
        <Text
          fontSize='md'
          color='white'
          fontWeight='normal'
          mt='10px'
          mb='26px'
          w={{ base: "90%", sm: "60%", lg: "40%", xl: "30%" }}>
          Do more, legally.
        </Text>
      </Flex>
      <Flex alignItems='center' justifyContent='center' mb='60px' mt='20px'>
        <Flex
          direction='column'
          w='445px'
          background='transparent'
          borderRadius='15px'
          p='40px'
          mx={{ base: "100px" }}
          bg={bgColor}
          boxShadow='0 20px 27px 0 rgb(0 0 0 / 5%)'>
          <Text
            fontSize='xl'
            color={textColor}
            fontWeight='bold'
            textAlign='center'
            mb='5px'>
            Register for an account
          </Text>
          <Text
            fontSize='sm'
            color='gray.400'
            fontWeight='normal'
            textAlign='center'
            mb='15px'>
            Select whether you are a lawyer or client
          </Text>
          <HStack spacing='15px' justify='center' mb='22px'>
            <div>
              <Flex
                justify='center'
                align='center'
                w='75px'
                h='75px'
                bg={isLawyer ? 'blue' : 'white'}
                borderRadius='15px'
                border='1px solid lightgray'
                cursor='pointer'
                transition='all .25s ease'
                onClick={() => { 
                  setIsLawyer(true);
                  setIsCustomer(false);
                }}
                _hover={{ filter: "brightness(120%)", bg: 'blue' }}>
                {/* <Link href='#'> */}
                  <Icon
                    as={FaUserGraduate}
                    w='30px'
                    h='30px'
                    color={isLawyer ? 'white' : ''}
                    _hover={{ filter: "brightness(120%)", color: 'white' }}
                  />
                {/* </Link> */}
              </Flex>
              <Text
                fontSize='sm'
                color={isLawyer ? 'blue' : 'gray'}
                fontWeight='normal'
                textAlign='center'
                mb='15px'>
                Lawyer
              </Text>
            </div>
            <div>
              <Flex
                justify='center'
                align='center'
                w='75px'
                h='75px'
                bg={isCustomer ? 'blue' : 'white'}
                borderRadius='15px'
                border='1px solid lightgray'
                cursor='pointer'
                transition='all .25s ease'
                onClick={() => { 
                  setIsLawyer(false);
                  setIsCustomer(true);
                }}
                _hover={{ filter: "brightness(120%)", bg: 'blue' }}>
                {/* <Link href='#'> */}
                  <Icon
                    as={FaUser}
                    w='30px'
                    h='30px'
                    color={isCustomer ? 'white' : ''}
                    _hover={{ filter: "brightness(120%)", color: 'white' }}
                  />
                {/* </Link> */}
              </Flex>
              <Text
                fontSize='sm'
                color={isCustomer ? 'blue' : 'gray'}
                fontWeight='normal'
                textAlign='center'
                mb='15px'>
                Client
              </Text>
            </div>
          </HStack>
          <form onSubmit={signUpUser}>
            <FormControl>
              <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                First Name
              </FormLabel>
              <Input
                fontSize='sm'
                ms='4px'
                borderRadius='15px'
                type='text'
                placeholder='Your first name'
                onChange={e => setFirstName(e.target.value)}
                value={firstName}
                required
                mb='24px'
                size='lg'
              />
              <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                Last Name
              </FormLabel>
              <Input
                fontSize='sm'
                ms='4px'
                borderRadius='15px'
                type='text'
                placeholder='Your last name'
                onChange={e => setLastName(e.target.value)}
                value={lastName}
                required
                mb='24px'
                size='lg'
              />
              <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                Phone
              </FormLabel>
              {/* <Input
                fontSize='sm'
                ms='4px'
                borderRadius='15px'
                type='email'
                placeholder='Your email address'
                onChange={e => setEmail(e.target.value)}
                value={email}
                required
                mb='24px'
                size='lg'
              /> */}
              <div style={{ marginBottom: '24px' }}>
                <PhoneInput
                  country={'ke'}
                  value={phone}
                  onChange={phone => setPhone(phone)}
                  countryCodeEditable={false}
                  inputProps={{
                    required: true
                  }}
                  inputStyle={{ 
                    borderRadius: '15px',
                    border: '1px solid #e2e8f0',
                    height: '50px',
                    width: '100%'
                  }}
                  buttonStyle={{ 
                    borderTopLeftRadius: '15px',
                    borderBottomLeftRadius: '15px'
                  }}
                />
              </div>
              <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                Password
              </FormLabel>
              <Input
                fontSize='sm'
                ms='4px'
                borderRadius='15px'
                type='password'
                placeholder='Your password'
                onChange={e => setPassword(e.target.value)}
                value={password}
                required
                mb='24px'
                size='lg'
              />
              {
                errorMessage.length > 0 ? (
                  <Alert status='error' mb='24px'>
                    <AlertIcon />
                    There was an error processing your request
                  </Alert>
                ) : (
                  null
                )
              }
              <Button
                isLoading={loading}
                loadingText="Signing you up..."
                type='submit'
                bg='blue'
                fontSize='10px'
                color='white'
                fontWeight='bold'
                w='100%'
                h='45'
                mb='24px'
                _hover={{
                  bg: "blue.200",
                }}
                _active={{
                  bg: "blue.400",
                }}>
                SIGN UP
              </Button>
            </FormControl>
          </form>
          <Flex
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
            maxW='100%'
            mt='0px'>
            <Text color={textColor} fontWeight='medium'>
              Already have an account?
              <Link
                color={titleColor}
                as={RouterLink}
                ms='5px'
                to='/auth/signin'
                fontWeight='bold'>
                Sign In
              </Link>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default SignUp;
