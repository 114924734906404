// Chakra imports
import {
  Alert,
  AlertIcon,
  Avatar,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Textarea,
  Text,
  useColorModeValue,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
// Assets
import BgSignUp from "assets/img/BgSignUp.png";
import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { FaUserGraduate, FaUser } from "react-icons/fa";
import axios from "axios";
import { ENDPOINTS } from "../../../api/constants.js";

function AddCase(props) {
  const titleColor = useColorModeValue("blue", "blue.200");
  const textColor = useColorModeValue("gray.700", "white");
  const bgColor = useColorModeValue("white", "gray.700");
  const bgIcons = useColorModeValue("blue", "rgba(255, 255, 255, 0.5)");
  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [caseDetail, setCaseDetails] = useState('');
  const [lawyerDetails, setLawyerDetails] = useState({});
  const [errorMessage, setErrorMessage] = useState('');

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [open, setOpen] = useState(true);

  useEffect(() => {
    try {
      const user = JSON.parse(localStorage.getItem('userDetails'));

      if (user.user.role === 'lawyer') {
        props.history.goBack();
      }
    } catch (error) {
      console.log(error);
      // setCasesLoading(false);
    }
  }, []);

  const submitCase = e => {
    e.preventDefault();
    setLoading(true);

    try {
      const user = JSON.parse(localStorage.getItem('userDetails'));

      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.token}`
        }
      };

      const data = {
        caseDetails: caseDetail
      };

      const url = process.env.REACT_APP_API_BASE + ENDPOINTS.cases + '/match';

      axios
        .post(url, data, config)
        .then((res) => {
          // localStorage.setItem('userDetails', JSON.stringify(res.data));
          setLawyerDetails(res?.data?.data);
          setLoading(false);
          onOpen();
        })
        .catch((error) => {
          setLoading(false);
          if (error.response) {
            console.log(error);
            setErrorMessage('Problem signin up');
          } else if (error.request) {
            console.log(error);
            setErrorMessage('Problem signin up');
          } else {
            console.log(error);
            setErrorMessage('Problem signin up');
          }
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
      setErrorMessage('Problem signin up');
    }
  };

  const saveMatchedCase = () => {
    setConfirmLoading(true);

    try {
      const user = JSON.parse(localStorage.getItem('userDetails'));

      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.token}`
        }
      };

      const data = {
        userId: user.user.id,
        description: caseDetail,
        lawyerMatched: true,
        lawyerId: lawyerDetails?.userId?._id,
        status: 'matched',
        lawyerStatus: "pending"
      };

      const url = process.env.REACT_APP_API_BASE + ENDPOINTS.cases;

      axios
        .post(url, data, config)
        .then((res) => {
          setConfirmLoading(false);
          props.history.push('/admin/case-add-success');
        })
        .catch((error) => {
          setLoading(false);
          if (error.response) {
            console.log(error);
            setErrorMessage('Problem signin up');
          } else if (error.request) {
            console.log(error);
            setErrorMessage('Problem signin up');
          } else {
            console.log(error);
            setErrorMessage('Problem signin up');
          }
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
      setErrorMessage('Problem signin up');
    }
  };

  return (
    <>
      <Flex
        direction='column'
        alignSelf='center'
        justifySelf='center'
        overflow='hidden'>
        <Flex alignItems='center' justifyContent='center' mb='60px' mt='20px'>
          <Flex
            direction='column'
            w='95%'
            background='transparent'
            borderRadius='15px'
            p='40px'
            mt='5rem'
            bg={bgColor}
            boxShadow='0 20px 27px 0 rgb(0 0 0 / 5%)'>
            <Text
              fontSize='xl'
              color={textColor}
              fontWeight='bold'
              textAlign='center'
              mb='5px'>
              Create your case
            </Text>
            <Text
              fontSize='sm'
              color='gray.400'
              fontWeight='normal'
              textAlign='center'
              mb='15px'>
              Please enter the details of your case
            </Text>
            <form onSubmit={submitCase}>
              <FormControl>
                <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                  Your case details
                </FormLabel>
                <Textarea
                  fontSize='sm'
                  ms='4px'
                  borderRadius='15px'
                  value={caseDetail}
                  onChange={e => setCaseDetails(e.target.value)}
                  mb='24px'
                  size='lg'
                  h={'300px'}
                  placeholder='Enter details of issue'
                  required />
                {
                  errorMessage.length > 0 ? (
                    <Alert status='error' mb='24px'>
                      <AlertIcon />
                      There was an error processing your request
                    </Alert>
                  ) : (
                    null
                  )
                }
                <Button
                  isLoading={loading}
                  loadingText="Submitting case..."
                  type='submit'
                  bg='blue'
                  fontSize='10px'
                  color='white'
                  fontWeight='bold'
                  w='100%'
                  h='45'
                  mb='24px'
                  _hover={{
                    bg: "blue.200",
                  }}
                  _active={{
                    bg: "blue.400",
                  }}>
                  Submit Case
                </Button>
              </FormControl>
            </form>
          </Flex>
        </Flex>
      </Flex>

      {
        loading ? (
          <div>
            <div className="blob"><h1>Matching...</h1></div>
            <div className="blob1"></div>
            <div className="blob2"></div>
            <div className="blob3">
            </div>
          </div>
        ) : (
          null
        )
      }

      <Modal closeOnOverlayClick={false} size={"xl"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Congratulations!</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb={8}>Your case has been successsfully matched with a lawyer.<br />These are their details.</Text>

            <Flex spacing='4' mb={8}>
              <Flex flex='1' gap='4' alignItems='center' flexWrap='wrap'>
                <Avatar name={lawyerDetails?.userId?.firstName + " " + lawyerDetails?.userId?.lastName} src='' />
                <Box>
                  <Heading size='sm'>{lawyerDetails?.userId?.firstName + " " + lawyerDetails?.userId?.lastName}</Heading>
                  <Text>{lawyerDetails?.categoryId?.categoryName}</Text>
                </Box>
              </Flex>
            </Flex>

            <Text>Click the button to accept.</Text>
          </ModalBody>

          <ModalFooter>
            <Button variant='ghost' onClick={onClose}>Cancel</Button>
            <Button bg='blue' color='white' _hover={{ bg: "blue.200" }} mr={3} isLoading={confirmLoading} onClick={saveMatchedCase}>
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default AddCase;
