// Chakra imports
import {
  Button, 
  Flex,
  Text,
  Spinner
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import Authors from "./components/Authors";
import Projects from "./components/Projects";
import { tablesTableData, dashboardTableData } from "variables/general";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import axios from "axios";
import { ENDPOINTS } from "../../../api/constants";

function CasesList(props) {
  const [loading, setLoading] = useState(true);
  const [cases, setCases] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    try {
      const user = JSON.parse(localStorage.getItem('userDetails'));

      if(user.user.role === 'lawyer' && user.user.lawyerOnboarded === false) {
        props.history.push('/admin/lawyer-onboard');
      }

      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.token}`
        }
      };

      var userTypeUrl = user.user.role === 'lawyer' ? 'lawyer/' : 'client/';

      const url = process.env.REACT_APP_API_BASE + ENDPOINTS.cases + '/' + userTypeUrl + user.user.id;

      axios
        .get(url, config)
        .then((res) => {
          setLoading(false);
          setCases(res.data.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error);
            setLoading(false);
          } else if (error.request) {
            console.log(error);
            setLoading(false);
          } else {
            console.log(error);
            setLoading(false);
          }
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  return (
    <Flex direction='column' pt={{ base: "120px", md: "75px" }}>
      {
        loading ? (
          <Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue'
            size='xl'
            ml='45%'
          />
        ) : (
          <>
            {
              JSON.parse(localStorage.getItem('userDetails')).user.role === 'client' ? (
                <div style={{ textAlign: 'end' }}>
                  <Button
                    fontSize='10px'
                    bg='blue'
                    w='30%'
                    h='45'
                    mb='20px'
                    color='white'
                    mt='20px'
                    _hover={{
                      bg: "blue.200",
                    }}
                    _active={{
                      bg: "blue.400",
                    }}
                    onClick={() => props.history.push('/admin/add-case')}>+ Add Case</Button>
                </div>
              ) : (
                null
              )
            }
            
            {
              cases.length !== 0 ? (
                <Projects
                  title={"Cases"}
                  amount={5}
                  captions={["Case ID", "Description", "Date Created", "Status", ""]}
                  data={cases}
                />
              ) : (
                <Card p='16px' overflowX={{ sm: "scroll", xl: "hidden" }}>
                  <CardHeader p='12px 0px 28px 0px'>
                    <Flex direction='column'>
                      <Text fontSize='lg' fontWeight='bold' pb='.5rem'>
                        Cases
                      </Text>
                      <Flex align='center'>
                        <Text fontSize='sm' color='gray.400' fontWeight='normal'>
                          <Text fontWeight='bold' as='span'>
                            Your cases will be listed here
                          </Text>
                        </Text>
                      </Flex>
                    </Flex>
                  </CardHeader>
                  <CardBody px='5px'>
                    <Text fontSize='sm' color='gray.500' fontWeight='bold' textAlign={'center'}>
                      No cases yet
                    </Text>
                  </CardBody>
                </Card>
              )
            }
          </>
        )
      }
      
    </Flex>
  );
}

export default CasesList;
