/*!

=========================================================
* Purity UI Dashboard - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/purity-ui-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/purity-ui-dashboard/blob/master/LICENSE.md)

* Design by Creative Tim & Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import PrivateRoute from "./api/PrivateRoute";

import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
// import RTLLayout from "layouts/RTL.js";
import './assets/css/style.css';
import mixpanel from 'mixpanel-browser';
 
mixpanel.init('02fb11e74c8bc23877b9701c9eb49ad9', {debug: true, track_pageview: true, persistence: 'localStorage'});
 
// Set this to a unique identifier for the user performing the event.
// mixpanel.identify('USER_ID')
 
// Track an event. It can be anything, but in this example, we're tracking a Sign Up event.
mixpanel.track('Sign Up', {
  'Signup Type': 'Referral'
})

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path={`/auth`} component={AuthLayout} />
      <PrivateRoute path={`/admin`} component={AdminLayout} />
      {/* <Route path={`/rtl`} component={RTLLayout} /> */}
      <Redirect from={`/`} to={"/auth/login"} />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
