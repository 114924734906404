// Chakra imports
import {
  Flex,
  Grid,
  Image,
  SimpleGrid,
  useColorModeValue,
  Text,
  Spinner
} from "@chakra-ui/react";
// assets
import peopleImage from "assets/img/people-image.png";
import logoChakra from "assets/svg/logo-white.svg";
import BarChart from "components/Charts/BarChart";
import LineChart from "components/Charts/LineChart";
// Custom icons
import {
  CartIcon,
  DocumentIcon,
  GlobeIcon,
  WalletIcon,
} from "components/Icons/Icons.js";
import React, { useState, useEffect } from "react";
import { dashboardTableData, timelineData } from "variables/general";
import ActiveUsers from "./components/ActiveUsers";
import BuiltByDevelopers from "./components/BuiltByDevelopers";
import MiniStatistics from "./components/MiniStatistics";
import OrdersOverview from "./components/OrdersOverview";
import Projects from "./components/Projects";
import SalesOverview from "./components/SalesOverview";
import WorkWithTheRockets from "./components/WorkWithTheRockets";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import axios from "axios";
import { ENDPOINTS } from "../../../api/constants";

export default function Dashboard(props) {
  const iconBoxInside = useColorModeValue("white", "white");
  const [loading, setLoading] = useState(true);
  const [casesLoading, setCasesLoading] = useState(true);
  const [cases, setCases] = useState([]);
  const [matchedCases, setMatchedCases] = useState([]);
  const [resolvedCases, setResolvedCases] = useState([]);
  const [pendingCases, setPendingCases] = useState([]);
  const [acceptedCases, setAcceptedCases] = useState([]);
  const [user, setUser] = useState({})
  const [error, setError] = useState(false);

  useEffect(() => {
    try {
      const user = JSON.parse(localStorage.getItem('userDetails'));

      //save user to state to extract role
      setUser(user.user)

      if (user.user.role === 'lawyer' && user.user.lawyerOnboarded === false) {
        props.history.push('/admin/lawyer-onboard');
      }

      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.token}`
        }
      };

      var userTypeUrl = user.user.role === 'lawyer' ? 'lawyer/' : 'client/';

      const url = process.env.REACT_APP_API_BASE + ENDPOINTS.cases + '/' + userTypeUrl + user.user.id;

      axios
        .get(url, config)
        .then((res) => {
          setCasesLoading(false);
          setCases(res.data.data?.slice(0, 4));
        })
        .catch((error) => {
          if (error.response) {
            console.log(error);
            setCasesLoading(false);
          } else if (error.request) {
            console.log(error);
            setCasesLoading(false);
          } else {
            console.log(error);
            setCasesLoading(false);
          }
        });
    } catch (error) {
      console.log(error);
      setCasesLoading(false);
    }
  }, []);

  //check whether the user is a lawyer or client and update the UI with case count
  if (user?.role === 'lawyer') {
    useEffect(() => {
      setAcceptedCases(cases.filter((c) => c.lawyerStatus === "accepted"))
      setPendingCases(cases.filter((c) => c.lawyerStatus === "pending"))
    }, [cases])
  } else {
    useEffect(() => {
      setMatchedCases(cases.filter((c) => c.status === "matched"))
      setResolvedCases(cases.filter((c) => c.status === "resolved"))
    }, [cases])
  }



  return (
    <Flex flexDirection='column' pt={{ base: "120px", md: "75px" }}>
      <SimpleGrid columns={{ sm: 1, md: 2, xl: 4 }} spacing='24px' mb='24px'>
        <MiniStatistics
          title={"Cases"}
          amount={cases.length}
          icon={<DocumentIcon h={"24px"} w={"24px"} color={iconBoxInside} />}
        />
        <MiniStatistics
          title={user?.role === 'lawyer' ? "Cases Pending" : "Cases Matched"}
          amount={user?.role === 'lawyer' ? pendingCases.length : matchedCases.length}
          icon={<GlobeIcon h={"24px"} w={"24px"} color={iconBoxInside} />}
        />
        <MiniStatistics
          title={user?.role === 'lawyer' ? "Cases Accepted" : "Cases Resolved"}
          amount={user?.role === 'lawyer' ? acceptedCases.length : resolvedCases.length}
          icon={<GlobeIcon h={"24px"} w={"24px"} color={iconBoxInside} />}
        />
        <MiniStatistics
          title={"Amount Paid"}
          amount={"$0"}
          icon={<WalletIcon h={"24px"} w={"24px"} color={iconBoxInside} />}
        />
      </SimpleGrid>
      {/* <Grid
        templateColumns={{ md: "1fr", lg: "1.8fr 1.2fr" }}
        templateRows={{ md: "1fr auto", lg: "1fr" }}
        my='26px'
        gap='24px'>
        <BuiltByDevelopers
          title={"Built by Developers"}
          name={"Purity UI Dashboard"}
          description={
            "From colors, cards, typography to complex elements, you will find the full documentation."
          }
          image={
            <Image
              src={logoChakra}
              alt='chakra image'
              minWidth={{ md: "300px", lg: "auto" }}
            />
          }
        />
        <WorkWithTheRockets
          backgroundImage={peopleImage}
          title={"Work with the rockets"}
          description={
            "Wealth creation is a revolutionary recent positive-sum game. It is all about who takes the opportunity first."
          }
        />
      </Grid> */}
      {/* <Grid
        templateColumns={{ sm: "1fr", lg: "1.3fr 1.7fr" }}
        templateRows={{ sm: "repeat(2, 1fr)", lg: "1fr" }}
        gap='24px'
        mb={{ lg: "26px" }}>
        <ActiveUsers
          title={"Active Users"}
          percentage={23}
          chart={<BarChart />}
        />
        <SalesOverview
          title={"Sales Overview"}
          percentage={5}
          chart={<LineChart />}
        />
      </Grid> */}
      {
        casesLoading ? (
          <Spinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue'
            size='xl'
            ml='45%'
          />
        ) : (
          <Grid
            templateColumns={{ sm: "1fr", md: "1fr", lg: "1fr" }}
            templateRows={{ sm: "1fr auto", md: "1fr", lg: "1fr" }}
            gap='24px'>
            {
              cases.length !== 0 ? (
                <Projects
                  title={"Cases"}
                  amount={cases.length}
                  captions={["Case ID", "Description", "Date Created", "Status"]}
                  data={cases}
                />
              ) : (
                <Card p='16px' overflowX={{ sm: "scroll", xl: "hidden" }}>
                  <CardHeader p='12px 0px 28px 0px'>
                    <Flex direction='column'>
                      <Text fontSize='lg' fontWeight='bold' pb='.5rem'>
                        Cases
                      </Text>
                      <Flex align='center'>
                        <Text fontSize='sm' color='gray.400' fontWeight='normal'>
                          <Text fontWeight='bold' as='span'>
                            Your cases will be listed here
                          </Text>
                        </Text>
                      </Flex>
                    </Flex>
                  </CardHeader>
                  <CardBody px='5px'>
                    <Text fontSize='sm' color='gray.500' fontWeight='bold' textAlign={'center'}>
                      No cases yet
                    </Text>
                  </CardBody>
                </Card>
              )
            }

            {/* <OrdersOverview
              title={"Orders Overview"}
              amount={30}
              data={timelineData}
            /> */}
          </Grid>
        )
      }

    </Flex>
  );
}
