// Chakra imports
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Flex,
  Link,
  Spinner,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import BgSignUp from "assets/img/BgSignUp.png";
import React, {useState, useEffect} from "react";
import { Link as RouterLink } from "react-router-dom";
import axios from "axios";
import { ENDPOINTS } from "../../api/constants";

function SuccessfulActivation(props) {
  const titleColor = useColorModeValue("blue", "blue.200");
  const textColor = useColorModeValue("gray.700", "white");
  const bgColor = useColorModeValue("white", "gray.700");
  const bgIcons = useColorModeValue("blue", "rgba(255, 255, 255, 0.5)");
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };

      const data = {
        uid: props.match.params.uid,
        token: props.match.params.token
      };

      const url = process.env.REACT_APP_API_BASE + ENDPOINTS.auth + 'users/activation/';

      axios
        .post(url, data, config)
        .then((res) => {
          setLoading(false);
          setSuccess(true);
        })
        .catch((error) => {
          if (error.response) {
            setLoading(false);
            setError(true);
          } else if (error.request) {
            setLoading(false);
            setError(true);
          } else {
            setLoading(false);
            setError(true);
          }
        });
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  }, []);


  return (
    <Flex
      direction='column'
      alignSelf='center'
      justifySelf='center'
      overflow='hidden'>
      <Box
        position='absolute'
        minH={{ base: "70vh", md: "50vh" }}
        w={{ md: "calc(100vw - 50px)" }}
        borderRadius={{ md: "15px" }}
        left='0'
        right='0'
        bgRepeat='no-repeat'
        overflow='hidden'
        zIndex='-1'
        top='0'
        // bgImage={BgSignUp}
        // bg={'blue'}
        bgSize='cover'
        mx={{ md: "auto" }}
        mt={{ md: "14px" }}></Box>
      <Flex alignItems='center' justifyContent='center' mb='60px' mt='20px'>
        <Flex
          direction='column'
          w='445px'
          background='transparent'
          borderRadius='15px'
          p='0px'
          // mx={{ base: "100px" }}
          bg={bgColor}
          mt='10rem'
          boxShadow='0 20px 27px 0 rgb(0 0 0 / 5%)'>
            {
              loading ? (
                <Spinner color='blue' textAlign={'center'} marginLeft={'50%'} />
              ) : (
                success ? (
                  <Alert
                    status='success'
                    variant='subtle'
                    flexDirection='column'
                    alignItems='center'
                    justifyContent='center'
                    textAlign='center'
                    height='200px'
                  >
                    <AlertIcon boxSize='40px' mr={0} />
                    <AlertTitle mt={4} mb={1} fontSize='lg'>
                      Account Activated!
                    </AlertTitle>
                    <AlertDescription maxWidth='sm'>
                      Your account has been successfully activated. <Link
                        color={titleColor}
                        as={RouterLink}
                        ms='5px'
                        to='/auth/signin'
                        fontWeight='bold'>
                        Click here to log in
                      </Link>
                    </AlertDescription>
                  </Alert>
                ) : (
                  <Alert
                    status='error'
                    variant='subtle'
                    flexDirection='column'
                    alignItems='center'
                    justifyContent='center'
                    textAlign='center'
                    height='200px'
                  >
                    <AlertIcon boxSize='40px' mr={0} />
                    <AlertTitle mt={4} mb={1} fontSize='lg'>
                      Oops!
                    </AlertTitle>
                    <AlertDescription maxWidth='sm'>
                      Account cannot be activated right now. Please try again later!
                    </AlertDescription>
                  </Alert>
                )
              )
            }
          
        </Flex>
      </Flex>
    </Flex>
  );
}

export default SuccessfulActivation;
