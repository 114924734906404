// Chakra imports
import { Flex, Grid, Stack, Skeleton, useColorModeValue } from "@chakra-ui/react";
import avatar4 from "assets/img/avatars/avatar4.png";
import ProfileBgImage from "assets/img/ProfileBackground.png";
import React, {useState, useEffect} from "react";
import { FaCube, FaPenFancy } from "react-icons/fa";
import { IoDocumentsSharp } from "react-icons/io5";
import Conversations from "./components/Conversations";
import Header from "./components/Header";
import PlatformSettings from "./components/PlatformSettings";
import ProfileInformation from "./components/ProfileInformation";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Projects from "./components/Projects";
import axios from "axios";
import { ENDPOINTS } from "../../../api/constants";

function Profile(props) {
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const bgProfile = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [profileData, setProfileData] = useState({});

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('userDetails'));

    if(user.user.role === 'lawyer' &&  user.user.lawyerOnboarded === false) {
      props.history.push('/admin/lawyer-onboard');
    }

    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.token}`
        }
      };

      const url = process.env.REACT_APP_API_BASE + ENDPOINTS.auth + '/me/' + user.user.id;

      axios
        .get(url, config)
        .then((res) => {
          setLoading(false);
          setProfileData(res.data.data);
        })
        .catch((error) => {
          if (error.response) {
            setLoading(false);
            setError(true);
          } else if (error.request) {
            setLoading(false);
            setError(true);
          } else {
            setLoading(false);
            setError(true);
          }
        });
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  }, []);

  return (
    <Flex direction='column'>
      <Header
        backgroundHeader={ProfileBgImage}
        backgroundProfile={bgProfile}
        avatarImage={profileData.img}
        loading={loading}
        name={JSON.parse(localStorage.getItem('userDetails')).user.firstName + ' ' + JSON.parse(localStorage.getItem('userDetails')).user.lastName}
        email={profileData.email}
        tabs={[
          {
            name: "OVERVIEW",
            icon: <FaCube w='100%' h='100%' />,
          }
        ]}
      />
      {
        loading ? (
          <Grid templateColumns={{ sm: "1fr", xl: "repeat(2, 1fr)" }} gap='22px'>
            <Skeleton height='20px' />
            <Skeleton height='20px' />
            <Skeleton height='20px' />
            <Skeleton height='20px' />
            <Skeleton height='20px' />
            <Skeleton height='20px' />
            <Skeleton height='20px' />
            <Skeleton height='20px' />
            <Skeleton height='20px' />
            <Skeleton height='20px' />
            <Skeleton height='20px' />
            <Skeleton height='20px' />
          </Grid>
        ) : (
          <Grid templateColumns={{ sm: "1fr", xl: "repeat(2, 1fr)" }} gap='22px'>
            <ProfileInformation
              title={"Profile Information"}
              description={
                JSON.parse(localStorage.getItem('userDetails')).user.role
              }
              name={JSON.parse(localStorage.getItem('userDetails')).user.firstName + ' ' + JSON.parse(localStorage.getItem('userDetails')).user.lastName}
              mobile={profileData.phone}
              // email={profileData.lawyer_email}
              // location={profileData.location}
            />
            <Conversations title={"Conversations"} />
          </Grid>
        )
      }
    </Flex>
  );
}

export default Profile;
