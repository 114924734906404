import React, {useState} from "react";
// Chakra imports
import {
  Alert,
  AlertIcon,
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  Link,
  Switch,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import { Link as RouterLink } from "react-router-dom";
import signInImage from "assets/img/logowhite.png";
import axios from "axios";
import { ENDPOINTS } from "../../api/constants.js";

function SetNewPassword(props) {
  // Chakra color mode
  const titleColor = useColorModeValue("blue", "blue.200");
  const textColor = useColorModeValue("gray.400", "white");
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [cPassword, setCPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const submitPassword = e => {
    e.preventDefault();
    setLoading(true);

    if(password !== cPassword) {
      setLoading(false);
      setErrorMessage('Your passwords do not match');
    }
    else {
      try {
        const config = {
          headers: {
            'Content-Type': 'application/json'
          }
        };
  
        const data = {
          uid: props.match.params.uid,
          token: props.match.params.token,
          new_password: password
        };
  
        const url = process.env.REACT_APP_API_BASE + ENDPOINTS.auth + 'users/reset_password_confirm/';
  
        axios
          .post(url, data, config)
          .then((res) => {
            props.history.push('/auth/set-password-success');
          })
          .catch((error) => {
            setLoading(false);
            if (error.response) {
              console.log(error);
              setErrorMessage('Problem setting password');
            } else if (error.request) {
              console.log(error);
              setErrorMessage('Problem setting password');
            } else {
              console.log(error);
              setErrorMessage('Problem setting password');
            }
          });
      } catch (error) {
        console.log(error);
        setLoading(false);
        setErrorMessage('Problem setting password');
      }
    }
  }

  return (
    <Flex position='relative' /* mb='40px' */>
      <Flex
        h={{ sm: "initial", md: "95vh", lg: "95vh" }}
        w='100%'
        maxW='1044px'
        mx='auto'
        justifyContent='space-between'
        mb='30px'
        pt={{ sm: "100px", md: "0px" }}>
        <Flex
          alignItems='center'
          justifyContent='start'
          style={{ userSelect: "none" }}
          w={{ base: "100%", md: "50%", lg: "42%" }}>
          <Flex
            direction='column'
            w='100%'
            background='transparent'
            p='48px'
            mt={{ md: "150px", lg: "80px" }}>
            <Heading color={titleColor} fontSize='32px' mb='10px'>
              Change password
            </Heading>
            <Text
              mb='36px'
              ms='4px'
              color={textColor}
              fontWeight='bold'
              fontSize='14px'>
              Enter and confirm your new password
            </Text>
            <form onSubmit={submitPassword}>
              <FormControl>
                <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                  Password
                </FormLabel>
                <Input
                  borderRadius='15px'
                  mb='36px'
                  fontSize='sm'
                  type='password'
                  placeholder='Your password'
                  onChange={e => setPassword(e.target.value)}
                  value={password}
                  required
                  size='lg'
                />
                <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                  Confirm Password
                </FormLabel>
                <Input
                  borderRadius='15px'
                  mb='36px'
                  fontSize='sm'
                  type='password'
                  placeholder='Confirm password'
                  onChange={e => setCPassword(e.target.value)}
                  value={cPassword}
                  required
                  size='lg'
                />
                {
                  errorMessage.length > 0 ? (
                    <Alert status='error' mb='24px'>
                      <AlertIcon />
                      {errorMessage}
                    </Alert>
                  ) : (
                    null
                  )
                }
                <Button
                  isLoading={loading}
                  loadingText="Submitting..."
                  fontSize='10px'
                  type='submit'
                  bg='blue'
                  w='100%'
                  h='45'
                  mb='20px'
                  color='white'
                  mt='20px'
                  _hover={{
                    bg: "blue.200",
                  }}
                  _active={{
                    bg: "blue.400",
                  }}>
                  Set Password
                </Button>
              </FormControl>
            </form>
          </Flex>
        </Flex>
        <Box
          display={{ base: "none", md: "block" }}
          overflowX='hidden'
          h='100%'
          w='40vw'
          position='absolute'
          right='0px'>
          <Box
            // bgImage={signInImage}
            w='100%'
            h='100%'
            bg={'blue'}
            bgSize='cover'
            bgPosition='50%'
            alignContent='center'
            position='absolute'
            borderBottomLeftRadius='20px'>
              <Image
                // boxSize="100px"
                w={300}
                h={75}
                style={{ marginTop: '40%', marginLeft: '25%' }}
                src={signInImage}
                alt=""
              />
            </Box>
        </Box>
      </Flex>
    </Flex>
  );
}

export default SetNewPassword;
