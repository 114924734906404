// Chakra imports
import {
    Alert,
    AlertIcon,
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    HStack,
    Icon,
    Input,
    Link,
    Switch,
    Text,
    useColorModeValue,
  } from "@chakra-ui/react";
  // Assets
  import BgSignUp from "assets/img/BgSignUp.png";
  import React, {useState} from "react";
  import { Link as RouterLink } from "react-router-dom";
  import { FaUserGraduate, FaUser } from "react-icons/fa";
  import axios from "axios";
  import { ENDPOINTS } from "../../api/constants.js";
  import PhoneInput from 'react-phone-input-2'
  import 'react-phone-input-2/lib/style.css'
  
  function VerifyCode(props) {
    const titleColor = useColorModeValue("blue", "blue.200");
    const textColor = useColorModeValue("gray.700", "white");
    const bgColor = useColorModeValue("white", "gray.700");
    const bgIcons = useColorModeValue("blue", "rgba(255, 255, 255, 0.5)");
    const [loading, setLoading] = useState(false);
    const [isLawyer, setIsLawyer] = useState(false);
    const [isCustomer, setIsCustomer] = useState(false);
    const [code, setCode] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
  
    const verifyCode = e => {
      e.preventDefault();
      setLoading(true);
  
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
  
      const data = {
        phone: JSON.parse(localStorage.getItem('userDetails')).user.phone,
        code: code,
      };
  
      const url = process.env.REACT_APP_API_BASE + ENDPOINTS.auth + '/confirmcode/';
  
      axios
        .post(url, data, config)
        .then((res) => {
          // localStorage.setItem('userDetails', JSON.stringify(res.data));
          setLoading(false);
          props.history.push('/auth/signupsuccess');
        })
        .catch((error) => {
          setLoading(false);
          if (error.response) {
            console.log(error);
            setErrorMessage('Problem signin up');
          } else if (error.request) {
            console.log(error);
            setErrorMessage('Problem signin up');
          } else {
            console.log(error);
            setErrorMessage('Problem signin up');
          }
        });
    }
  
    return (
      <Flex
        direction='column'
        alignSelf='center'
        justifySelf='center'
        overflow='hidden'>
        <Box
          position='absolute'
          minH={{ base: "70vh", md: "50vh" }}
          w={{ md: "calc(100vw - 50px)" }}
          borderRadius={{ md: "15px" }}
          left='0'
          right='0'
          bgRepeat='no-repeat'
          overflow='hidden'
          zIndex='-1'
          top='0'
          // bgImage={BgSignUp}
          bg={'blue'}
          bgSize='cover'
          mx={{ md: "auto" }}
          mt={{ md: "14px" }}></Box>
        <Flex
          direction='column'
          textAlign='center'
          justifyContent='center'
          align='center'
          mt='6.5rem'
          mb='30px'>
          <Text fontSize='4xl' color='white' fontWeight='bold'>
            Welcome to Instruct
          </Text>
          <Text
            fontSize='md'
            color='white'
            fontWeight='normal'
            mt='10px'
            mb='26px'
            w={{ base: "90%", sm: "60%", lg: "40%", xl: "30%" }}>
            Do more, legally.
          </Text>
        </Flex>
        <Flex alignItems='center' justifyContent='center' mb='60px' mt='20px'>
          <Flex
            direction='column'
            w='445px'
            background='transparent'
            borderRadius='15px'
            p='40px'
            mx={{ base: "100px" }}
            bg={bgColor}
            boxShadow='0 20px 27px 0 rgb(0 0 0 / 5%)'>
            <Text
              fontSize='xl'
              color={textColor}
              fontWeight='bold'
              textAlign='center'
              mb='5px'>
              Verify Code
            </Text>
            <Text
              fontSize='sm'
              color='gray.400'
              fontWeight='normal'
              textAlign='center'
              mb='15px'>
              A code has been sent to your phone number. Kindly check and enter below.
            </Text>
            <form onSubmit={verifyCode}>
              <FormControl>
                <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                  Enter Code
                </FormLabel>
                <Input
                  fontSize='sm'
                  ms='4px'
                  borderRadius='15px'
                  type='text'
                  placeholder='000000'
                  onChange={e => setCode(e.target.value)}
                  value={code}
                  required
                  mb='24px'
                  size='lg'
                />
                {
                  errorMessage.length > 0 ? (
                    <Alert status='error' mb='24px'>
                      <AlertIcon />
                      There was an error processing your request
                    </Alert>
                  ) : (
                    null
                  )
                }
                <Button
                  isLoading={loading}
                  loadingText="Verying..."
                  type='submit'
                  bg='blue'
                  fontSize='10px'
                  color='white'
                  fontWeight='bold'
                  w='100%'
                  h='45'
                  mb='24px'
                  _hover={{
                    bg: "blue.200",
                  }}
                  _active={{
                    bg: "blue.400",
                  }}>
                  Verify Code
                </Button>
              </FormControl>
            </form>
          </Flex>
        </Flex>
      </Flex>
    );
  }
  
  export default VerifyCode;
  