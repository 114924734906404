// import
import Dashboard from "views/Dashboard/Dashboard";
import CasesList from "views/Dashboard/CasesList";
import Case from "views/Dashboard/Case";
import AddCase from "views/Dashboard/AddCase";
import SelectLawyer from "views/Dashboard/SelectLawyer";
import Billing from "views/Dashboard/Billing";
// import RTLPage from "views/Dashboard/RTL";
import Profile from "views/Dashboard/Profile";
import SignIn from "views/Auth/SignIn.js";
import SignUp from "views/Auth/SignUp.js";
import SignUpSuccess from "views/Auth/SignUpSuccess";
import SuccessfulActivation from "views/Auth/SuccessfulActivation";
import VerifyLink from "views/Auth/VerifyLink";

import {
  HomeIcon,
  StatsIcon,
  CreditIcon,
  PersonIcon,
  DocumentIcon,
  RocketIcon,
  SupportIcon,
} from "components/Icons/Icons";
import LawyerOnboard from "views/Dashboard/LawyerOnboard";
import SuccessfulSubmission from "views/Dashboard/SuccessfulSubmission";
import SuccessfulAddCase from "views/Dashboard/SuccessfulAddCase";
import ForgotPassword from "views/Auth/ForgotPassword";
import ForgotLinkSuccess from "views/Auth/ForgotLinkSuccess";
import SetNewPassword from "views/Auth/SetNewPassword";
import SetPasswordSuccess from "views/Auth/SetPasswordSuccess";
import VerifyCode from "views/Auth/VerifyCode";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color="inherit" />,
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/cases",
    name: "Cases",
    rtlName: "لوحة القيادة",
    icon: <StatsIcon color="inherit" />,
    component: CasesList,
    layout: "/admin",
  },
  {
    path: "/case/:caseId",
    name: "View Case",
    rtlName: "لوحة القيادة",
    icon: <StatsIcon color="inherit" />,
    component: Case,
    layout: "/admin",
    redirect: true,
  },
  {
    path: "/add-case",
    name: "Add Case",
    rtlName: "لوحة القيادة",
    icon: <StatsIcon color="inherit" />,
    component: AddCase,
    layout: "/admin",
    redirect: true,
  },
  {
    path: "/select-lawyer",
    name: "Select Lawyer",
    rtlName: "لوحة القيادة",
    icon: <StatsIcon color="inherit" />,
    component: SelectLawyer,
    layout: "/admin",
    redirect: true,
  },
  {
    path: "/billing",
    name: "Payments",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color="inherit" />,
    component: Billing,
    layout: "/admin",
  },
  {
    path: "/lawyer-onboard",
    name: "Onboarding",
    rtlName: "لوحة القيادة",
    icon: <StatsIcon color="inherit" />,
    component: LawyerOnboard,
    layout: "/admin",
    redirect: true,
  },
  {
    path: "/onboard-success",
    name: "Onboard Success",
    rtlName: "لوحة القيادة",
    icon: <StatsIcon color="inherit" />,
    component: SuccessfulSubmission,
    layout: "/admin",
    redirect: true,
  },
  {
    path: "/case-add-success",
    name: "Case Success",
    rtlName: "لوحة القيادة",
    icon: <StatsIcon color="inherit" />,
    component: SuccessfulAddCase,
    layout: "/admin",
    redirect: true,
  },
  /* {
    path: "/rtl-support-page",
    name: "RTL",
    rtlName: "آرتيإل",
    icon: <SupportIcon color="inherit" />,
    component: RTLPage,
    layout: "/rtl",
  }, */
  {
    name: "Account",
    category: "account",
    rtlName: "صفحات",
    state: "pageCollapse",
    views: [
      {
        path: "/profile",
        name: "Profile",
        rtlName: "لوحة القيادة",
        icon: <PersonIcon color="inherit" />,
        secondaryNavbar: true,
        component: Profile,
        layout: "/admin",
      },
      {
        path: "/login",
        name: "Sign In",
        rtlName: "لوحة القيادة",
        icon: <DocumentIcon color="inherit" />,
        component: SignIn,
        layout: "/auth",
        redirect: true,
      },
      {
        path: "/forgot-password",
        name: "Forgot Password",
        rtlName: "لوحة القيادة",
        icon: <DocumentIcon color="inherit" />,
        component: ForgotPassword,
        layout: "/auth",
        redirect: true,
      },
      {
        path: "/forgot-link-success",
        name: "Forgot Link Success",
        rtlName: "لوحة القيادة",
        icon: <DocumentIcon color="inherit" />,
        component: ForgotLinkSuccess,
        layout: "/auth",
        redirect: true,
      },
      {
        path: "/set-password/:uid/:token",
        name: "Set Password",
        rtlName: "لوحة القيادة",
        icon: <DocumentIcon color="inherit" />,
        component: SetNewPassword,
        layout: "/auth",
        redirect: true,
      },
      {
        path: "/set-password-success",
        name: "Successful Password Reset",
        rtlName: "لوحة القيادة",
        icon: <DocumentIcon color="inherit" />,
        component: SetPasswordSuccess,
        layout: "/auth",
        redirect: true,
      },
      {
        path: "/signup",
        name: "Sign Up",
        rtlName: "لوحة القيادة",
        icon: <RocketIcon color="inherit" />,
        secondaryNavbar: true,
        component: SignUp,
        layout: "/auth",
        redirect: true,
      },
      {
        path: "/verify-code",
        name: "Verify Code",
        rtlName: "لوحة القيادة",
        icon: <RocketIcon color="inherit" />,
        secondaryNavbar: true,
        component: VerifyCode,
        layout: "/auth",
        redirect: true,
      },
      {
        path: "/signupsuccess",
        name: "Sign Up Success",
        rtlName: "لوحة القيادة",
        icon: <RocketIcon color="inherit" />,
        secondaryNavbar: true,
        component: SignUpSuccess,
        layout: "/auth",
        redirect: true,
      },
      {
        path: "/activation/:uid/:token",
        name: "Sign Up Success",
        rtlName: "لوحة القيادة",
        icon: <RocketIcon color="inherit" />,
        secondaryNavbar: true,
        component: SuccessfulActivation,
        layout: "/auth",
        redirect: true,
      },
      {
        path: "/verifylink",
        name: "Verify Email",
        rtlName: "لوحة القيادة",
        icon: <RocketIcon color="inherit" />,
        secondaryNavbar: true,
        component: VerifyLink,
        layout: "/auth",
        redirect: true,
      },
    ],
  },
];
export default dashRoutes;
